@import "./variables.scss";
header.header-news,
header.header-property {
  height: 65px;
  width: 100%;
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  z-index: 100;
  transition: background-color 0.3s;
  box-shadow: 0 1px 30px 0 rgb(145 145 145 / 20%);
  .logo-wrapper {
    display: inline-block;
    .logo {
      text-decoration: none;
      display: flex;
      .logo-1h-wrapper {
        width: 75px;
        height: 65px;
        line-height: 65px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid var(--color-outline-variant);
        .logo-1h {
          vertical-align: top;
          text-align: center;
          display: inline-block;
          position: relative;
          width: 100%;
          height: 48px;
          text-decoration: none;
          font-size: 20px;
          margin: 0;
          color: var(--color-on-surfaces);
          box-sizing: border-box;
          transition: all 0.15s ease-in;
        }
      }
      .title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin: 13px 0 12px 18px;
        transition: all 0.15s ease-in;
        white-space: nowrap;
        opacity: 0;
      }
      &:hover .title,
      &.active .title {
        opacity: 1;
      }
    }
  }
  .search-container {
    flex: 1;
    line-height: 65px;
    height: 65px;
    margin: 0 auto;
    animation: fadeIn 0.35s ease-in forwards 1s;
    position: relative;
    .ant-form-item {
      min-width: 320px;
      background: #f4f7f8;
    }

    .input {
      border-radius: 20px;
      height: 40px;
      max-width: 710px;
      position: relative;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      i {
        height: 40px;
        line-height: 40px;
        color: #95a9b6;
        position: absolute;
        left: 15px;
        top: 0;
        pointer-events: none;
      }
      input[type="submit"] {
        position: absolute;
        left: -9999px;
      }
      input {
        height: 40px;
        line-height: 40px;
        display: block;
        width: 30vw;
        background: #f4f7f8;
        box-sizing: border-box;
        padding: 0 25px 0 35px;
        font-family: "Poppins";
        font-weight: 300;
        font-size: 14px;
        border-radius: 25px;
        background-color: #f4f7f8;
        border: none;
        &:focus {
          outline: none;
        }
        &:focus + .searchOptions {
          &:after {
            opacity: 0;
          }
          a {
            opacity: 1;
          }
        }

        &.secondary {
          height: 23px;
          line-height: 23px;
          position: absolute;
          left: 75%;
          right: 0;
          top: 9px;
          border-left: rgba(149, 169, 182, 0.3) solid 1px;
          border-radius: 0 20px 20px 0;
          transition:
            left 0.15s ease-in,
            padding 0.15s ease-in,
            width 0.15s ease-in,
            border 0.15s ease-in;
          padding: 0 15px;
          &:focus {
            z-index: 1;
            left: 35px;
            padding: 0 20px 0 0;
            width: 100%;
            border: none;
          }
        }
      }
    }
  }
  .pull-right {
    .nav-icon {
      opacity: 1;
      pointer-events: initial;
      margin: 12px 7px 0;
      display: inline-block;
      vertical-align: top;
      position: relative;
      transition: all 0.15s ease-in;
      .icon {
        position: relative;
        cursor: pointer;
        display: block;
        text-align: center;
        font-size: 14px;
        color: #303030;
        transition: all 0.15s ease-in;
        height: 40px;
        line-height: 34px;
        border-radius: 20px;
        box-sizing: border-box;
        width: 40px;
        border: solid 1px rgba(194, 194, 194, 0.3);
      }

      .link {
        display: block;
        width: auto;
        opacity: 0.7;
        font-size: 14px;
        letter-spacing: 1px;
        color: #000000;
        padding: 0 15px;
        border: solid 1px rgba(194, 194, 194, 0.3);
        transition: all 0.15s ease-in;
        position: relative;
        cursor: pointer;
        text-align: center;
        height: 40px;
        line-height: 38px;
        border-radius: 20px;
        box-sizing: border-box;
      }
      &.notifications {
        position: relative;
        z-index: 1;
      }
    }
    .user-name {
      height: 65px;
      line-height: 65px;
      margin: 0 20px 0px 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .user-icon {
      opacity: 1;
      width: 40px;
      position: relative;
      height: 65px;
      line-height: 65px;
      margin: 0 20px 0px 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        border: solid 1px #e4e4e4;
        vertical-align: middle;
        box-sizing: border-box;
      }
    }
  }
}

.header-nav {
  height: 65px;
  line-height: 65px;
  min-width: 520px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.tabs {
    background: #fff;
    position: fixed;
    z-index: 1001;
    transition:
      opacity 0.15s ease-in-out,
      top 0.15s ease-in-out;
    opacity: 0;

    top: -35px;
    opacity: 0;
    margin: 0;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    transition:
      opacity 0.15s ease-in-out,
      top 0.15s ease-in-out;
    a {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
      margin: 0 35px 0 0;
      position: relative;
      transition: color 0.15s ease-in;
      vertical-align: middle;
      line-height: initial;
      cursor: pointer;
    }
    &.active {
      top: 0;
      opacity: 1;
    }
  }
}
@media screen and (max-width: 767px) {
  header.header-news,
  header.header-property {
    .logo-wrapper {
      .logo {
        .logo-1h-wrapper {
          width: 50px;
          border-right: 0;
        }
      }
    }
  }
}
